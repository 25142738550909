import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import upperCase from 'lodash/upperCase'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import debounce from 'lodash/debounce'

// Base Page
import BasePage from '@/pages/Base'

// Services
import CurrencyService from '@/services/Currency'
import Rules from '@/services/Rules'
import CountryService from '@/services/Country'

@Component({})
export default class CurrencyIndex extends BasePage {
  constructor() {
    super()
  }

  // Add New
  addNewCurrency: boolean = false
  editMode: boolean = false
  newData: any = {
    from: null,
    to: null,
    value: null
  }

  // Filter Currencies
  currencies: any = []
  filter: any = {
    from: {
      items: [],
      selected: null,
      keyword: null,
      loading: false
    },
    to: {
      items: [],
      selected: null,
      keyword: null,
      loading: false
    }
  }

  // Edit Rule
  editedRule: string = ''

  // Table
  tableHeaders: object[] = [
    {
      text: 'From',
      align: 'left',
      sortable: true,
      value: 'currency_code'
    },
    {
      text: 'To',
      align: 'left',
      sortable: true,
      value: 'currency_code_target'
    },
    {
      text: 'Value / Rate',
      align: 'right',
      sortable: true,
      value: 'value'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: '-id',
    page: 1,
    rowsPerPage: 10,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true

  // Currency List
  showCurrency: boolean = false
  selectedCurrency: any = null
  currency: any = {
    items: [],
    selected: [],
    keyword: null,
    loading: false
  }

  selectedDelete: any = null
  showDeleteModal: boolean = false

  countries: any = {
    items: [],
    loading: false
  }

  @Watch('tablePagination', { deep: true })
  onTablePaginationChanged() {
    this.$vuetify.goTo(0)
    this.getCurrencies()
  }

  @Watch('filter.from.keyword')
  async onFromChanged() {
    // console.log('----', this.filter.from.keyword)
    const debouncing = debounce(async () => {
      this.filter.from.loading = true
      const response = await this.getCountries(this.filter.from.keyword)
      this.countries.items.forEach(item => this.filter.from.items.push(item))
      this.filter.from.loading = false
      return response
    }, 500)
    await debouncing()
  }

  @Watch('filter.to.keyword')
  async onToChanged() {
    const debouncing = debounce(async () => {
      this.filter.to.loading = true
      const response = await this.getCountries(this.filter.to.keyword)
      this.countries.items.forEach(item => this.filter.to.items.push(item))
      this.filter.to.loading = false
      return response
    }, 500)
    await debouncing()
  }

  @Watch('filter.from.selected')
  onFilterFromChanged() {
    this.$vuetify.goTo(0)
    this.getCurrencies()
    // if (
    //   (!isEmpty(this.filter.from.selected) && !isEmpty(this.filter.to.selected)) || (isEmpty(this.filter.from.selected) && isEmpty(this.filter.to.selected))
    // ) {
    //   this.$vuetify.goTo(0)
    //   this.getCurrencies()
    // }
  }
  @Watch('filter.to.selected')
  onFilterToChanged() {
  //   if (
  //     (!isEmpty(this.filter.from.selected) && !isEmpty(this.filter.to.selected)) || (isEmpty(this.filter.from.selected) && isEmpty(this.filter.to.selected))
  //   ) {
    this.$vuetify.goTo(0)
    this.getCurrencies()
  //   }
  }

  async getCountries (currency: String = '') {
    try {
      this.countries.loading = true
      const opts: any = {
        params: {
          'filter[currency][like]': currency
        }
      }

      if (currency === '') delete opts['filter[currency][like]']

      this.countries.items = []
      const response = await CountryService.getCountries(opts)
      if (response.data.success) {
        response.data.data.forEach(item => this.countries.items.push(item.attributes.currency))
      }
      return response
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.countries.loading = false
    }
  }

  async getCurrencies() {
    // console.log('>>>>>',this.filter.from.selected)
    // var filter = 'filter[currency_code][like]'
    // var filter2 = 'filter[currency_code_target][like]'
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        'sort': this.tablePagination.sortBy
        // 'filter[currency_code][like]':'',
        // 'filter[currency_code_target][like]':''
      }
    }
    if (!isEmpty(this.filter.from.selected)) {
      var filter = 'filter[currency_code][like]'
      opts.params[filter]= this.filter.from.selected
      // opts.params[filter2]= this.filter.to.selected
    }
    if (!isEmpty(this.filter.to.selected)) {
      // opts.params[filter]= this.filter.from.selected
      var filter2 = 'filter[currency_code_target][like]'
      opts.params[filter2]= this.filter.to.selected
    }
    // console.log('opts',opts)
    // if (!isEmpty(this.filter.from.selected) && !isEmpty(this.filter.to.selected)) {
    //   opts.params[filter]= this.filter.from.selected
    //   opts.params[filter2]= this.filter.to.selected
    // } else {
    //   delete opts.params.filter
    // }
    try {
      this.tableLoading = true
      const response = await CurrencyService.getCurrencies(opts)
      const responseData = response.data.data
      this.tableTotalItems = response.data.meta.pagination.total
      this.tableItems = []
      forEach(responseData, dataCurrency => {
        const currency = {
          id: dataCurrency.attributes.id,
          from: upperCase(dataCurrency.attributes.currency_code),
          to: upperCase(dataCurrency.attributes.currency_code_target),
          // value: this.formatMoney(dataCurrency.attributes.value),
          value: this.formatMoneyCurrency(dataCurrency.attributes.value),
          
          actionFab: false
        }
        if (dataCurrency.value < 1000) {
          currency.value = parseFloat(String(dataCurrency.attributes.value))
        }

        this.tableItems.push(currency)

        // this.currencies.push(currency.from)
        // this.currencies.push(currency.to)
        // this.currencies = sortBy(uniq(this.currencies))
      })
      
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  // async addCurrency() {
  //   try {
  //     const validationResponse = await this.$validator.validateAll('addNewCurrency')
  //     if (validationResponse) {
  //       this.addNewCurrency = false
  //       this.showLoading({ text: 'Saving Data...' })

  //       const payload = {
  //         currency_code: this.newData.from,
  //         currency_code_target: this.newData.to,
  //         value: this.newData.value
  //       }

  //       await CurrencyService.updateCurrency(payload)

  //       this.closeLoading()
  //       this.showSnackbar({ text: 'Saved Successfully!', color: 'green', timeout: 1500 })
  //       this.getCurrencies()
  //     } else this.showSnackbar({ text: 'Please check all field(s) requirements', color: 'primary', timeout: 2000 })
  //   } catch (error) {
  //     this.catchHandler(error)
  //   }
  // }

  openAddModal() {
    this.editMode = false
    this.addNewCurrency = true
  }

  async saveAddCurrency() {
    try{
      const valid = await this.$validator.validateAll('addNewCurrency')
      if (valid){
        const payload = {
          currency_code: this.newData.from,
          currency_code_target: this.newData.to,
          value: this.newData.value
        }

        this.showLoading({ text: 'Saving New Currency...'})
        this.cancelModal()

        const response = await CurrencyService.saveCurrency(payload, this.editMode ? this.editedRule : '')
        this.showSnackbar({
          text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' +response.status),
          color: response.status === 200 ? 'green' : 'red',
          timeout: 1500
        })

        await this.getCurrencies()
      } else {
        this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // openEditDialog(props: any) {
  //   this.addNewCurrency = true
  //   this.newData.from = props.item.from
  //   this.newData.to = props.item.to
  //   this.newData.value = this.unformatMoney(props.item.value)
  // }

  openEditDialog(props: any) {    
    this.editMode = true
    this.editedRule = props.item.id
    this.newData = {
      from: props.item.from,
      to: props.item.to,
      value: this.unformatMoney(props.item.value)
    }

    this.addNewCurrency = true
  }

  openDeleteModal(props) {
    this.selectedCurrency = props.item
    this.selectedDelete = props.item
    this.showDeleteModal = true
  }

  cancelModalCurrency() {
    this.currency.selected = null
    this.showCurrency = false
  }

  async deleteCurrency(){
    try {
      const payload = {
        id: [this.selectedCurrency.id],
        action: 'delete'
      }
      
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false

      const response = await CurrencyService.deleteCurrency(this.selectedCurrency.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' +response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })

      this.getCurrencies()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  cancelModal() {
    this.$validator.reset()
    this.newData.from = null
    this.newData.to = null
    this.newData.value = null
    this.addNewCurrency = false
  }
}
